import React from "react"
import { graphql } from "gatsby"
import Banner from "../components/banner/banner"
import Img from "gatsby-image"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import ContactDetails from "../components/contact/contact-details"

export default function Contact({ data }) {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <Banner
        image={
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            style={{ height: "100%" }}
          />
        }
        title="Contact Us"
      />
      <div className="width75">
        <ContactDetails />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "law-books.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
