import React from "react"
import styles from "./map.module.css"

const map = () => {
  return (
    <div>
      <iframe
        title="Google Map"
        className={styles.map}
        frameBorder="0"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyC0ynGe1BndS7W15Fi3T4jT0T2D-Nx__H0
          &q=place_id:ChIJbZl5FPVzdkgR7hvtOntG1yc"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default map
