import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from './form/form';
import Map from './map/map';
import styles from './contact-details.module.css';

export default function ContactDetails() {
    return (
        <>
            <div className={styles.details}>
                <span className={styles.detail}>
                    {/* <FontAwesomeIcon icon="map-marker-alt" className={styles.icon} /> */}
                    <p>10A First Floor, Station Road, Hayes, London, UB3 4DA</p>
                </span>
                <span className={styles.detail}>
                    <FontAwesomeIcon icon="phone" className={styles.icon} />
                    <a href="tel:020 3581 2854">020 3581 2854</a>
                </span>
                <span className={styles.detail}>
                    <FontAwesomeIcon icon="fax" className={styles.icon} />
                    <p>0203 475 8786</p>
                </span>
                <span className={styles.detail}>
                    <FontAwesomeIcon
                        icon="envelope-square"
                        className={styles.icon}
                    />
                    <a href="mailto:info@jjlawchambers.com">
                        info@jjlawchambers.com
                    </a>
                </span>
            </div>
            <div className={styles.grid}>
                {/* <h1 className={styles.formLabel}>Request a Call Back</h1> */}
                <h1 className={styles.mapLabel}>Find Us</h1>
                {/* <Form /> */}
                <Map />
            </div>
        </>
    );
}
